import { Authority } from '@/shared/security/authority';
/* tslint:disable */
// prettier-ignore
const Entities = () => import('@/entities/entities.vue');

const Onboarding = () => import('@/entities/onboarding/onboarding.vue');
const OnboardingUpdate = () => import('@/entities/onboarding/onboarding-update.vue');
const OnboardingDetails = () => import('@/entities/onboarding/onboarding-details.vue');

const Partner = () => import('@/entities/partner/partner.vue');
const PartnerUpdate = () => import('@/entities/partner/partner-update.vue');
const PartnerDetails = () => import('@/entities/partner/partner-details.vue');

const Operation = () => import('@/entities/operation/operation.vue');
const OperationUpdate = () => import('@/entities/operation/operation-update.vue');
const OperationDetails = () => import('@/entities/operation/operation-details.vue');

const Incentive = () => import('@/entities/incentive/incentive.vue');
const IncentiveUpdate = () => import('@/entities/incentive/incentive-update.vue');
const IncentiveDetails = () => import('@/entities/incentive/incentive-details.vue');

const Reward = () => import('@/entities/reward/reward.vue');
const RewardUpdate = () => import('@/entities/reward/reward-update.vue');
const RewardDetails = () => import('@/entities/reward/reward-details.vue');

const Referral = () => import('@/entities/referral/referral.vue');
const ReferralUpdate = () => import('@/entities/referral/referral-update.vue');
const ReferralDetails = () => import('@/entities/referral/referral-details.vue');

const UserLogin = () => import('@/entities/user-login/user-login.vue');
const UserLoginUpdate = () => import('@/entities/user-login/user-login-update.vue');
const UserLoginDetails = () => import('@/entities/user-login/user-login-details.vue');

const UserDevice = () => import('@/entities/user-device/user-device.vue');
const UserDeviceUpdate = () => import('@/entities/user-device/user-device-update.vue');
const UserDeviceDetails = () => import('@/entities/user-device/user-device-details.vue');

const Label = () => import('@/entities/label/label.vue');
const LabelUpdate = () => import('@/entities/label/label-update.vue');
const LabelDetails = () => import('@/entities/label/label-details.vue');

const LinkedLabel = () => import('@/entities/linked-label/linked-label.vue');
const LinkedLabelUpdate = () => import('@/entities/linked-label/linked-label-update.vue');
const LinkedLabelDetails = () => import('@/entities/linked-label/linked-label-details.vue');

const PhoneNumber = () => import('@/entities/phone-number/phone-number.vue');
const PhoneNumberUpdate = () => import('@/entities/phone-number/phone-number-update.vue');
const PhoneNumberDetails = () => import('@/entities/phone-number/phone-number-details.vue');

const MoneyRequest = () => import('@/entities/money-request/money-request.vue');
const MoneyRequestUpdate = () => import('@/entities/money-request/money-request-update.vue');
const MoneyRequestDetails = () => import('@/entities/money-request/money-request-details.vue');

const Purchase = () => import('@/entities/purchase/purchase.vue');
const PurchaseUpdate = () => import('@/entities/purchase/purchase-update.vue');
const PurchaseDetails = () => import('@/entities/purchase/purchase-details.vue');

const Organisation = () => import('@/entities/organisation/organisation.vue');
const OrganisationUpdate = () => import('@/entities/organisation/organisation-update.vue');
const OrganisationDetails = () => import('@/entities/organisation/organisation-details.vue');

const FutureTransaction = () => import('@/entities/future-transaction/future-transaction.vue');
const FutureTransactionUpdate = () => import('@/entities/future-transaction/future-transaction-update.vue');
const FutureTransactionDetails = () => import('@/entities/future-transaction/future-transaction-details.vue');

const ExchangeRate = () => import('@/entities/exchange-rate/exchange-rate.vue');
const ExchangeRateUpdate = () => import('@/entities/exchange-rate/exchange-rate-update.vue');
const ExchangeRateDetails = () => import('@/entities/exchange-rate/exchange-rate-details.vue');

const Bank = () => import('@/entities/bank/bank.vue');
const BankUpdate = () => import('@/entities/bank/bank-update.vue');
const BankDetails = () => import('@/entities/bank/bank-details.vue');

const EzpProduct = () => import('@/entities/ezp-product/ezp-product.vue');
const EzpProductUpdate = () => import('@/entities/ezp-product/ezp-product-update.vue');
const EzpProductDetails = () => import('@/entities/ezp-product/ezp-product-details.vue');

const EzpGift = () => import('@/entities/ezp-gift/ezp-gift.vue');
const EzpGiftUpdate = () => import('@/entities/ezp-gift/ezp-gift-update.vue');
const EzpGiftDetails = () => import('@/entities/ezp-gift/ezp-gift-details.vue');

const Biller = () => import('@/entities/biller/biller.vue');
const BillerUpdate = () => import('@/entities/biller/biller-update.vue');
const BillerDetails = () => import('@/entities/biller/biller-details.vue');

const BankTransaction = () => import('@/entities/bank-transaction/bank-transaction.vue');
const BankTransactionUpdate = () => import('@/entities/bank-transaction/bank-transaction-update.vue');
const BankTransactionDetails = () => import('@/entities/bank-transaction/bank-transaction-details.vue');

const Agent = () => import('@/entities/agent/agent.vue');
const AgentUpdate = () => import('@/entities/agent/agent-update.vue');
const AgentDetails = () => import('@/entities/agent/agent-details.vue');

const QrCard = () => import('@/entities/qr-card/qr-card.vue');
const QrCardUpdate = () => import('@/entities/qr-card/qr-card-update.vue');
const QrCardDetails = () => import('@/entities/qr-card/qr-card-details.vue');

const TransactionFee = () => import('@/entities/transaction-fee/transaction-fee.vue');
const TransactionFeeUpdate = () => import('@/entities/transaction-fee/transaction-fee-update.vue');
const TransactionFeeDetails = () => import('@/entities/transaction-fee/transaction-fee-details.vue');

const TransactionFeeTier = () => import('@/entities/transaction-fee-tier/transaction-fee-tier.vue');
const TransactionFeeTierUpdate = () => import('@/entities/transaction-fee-tier/transaction-fee-tier-update.vue');
const TransactionFeeTierDetails = () => import('@/entities/transaction-fee-tier/transaction-fee-tier-details.vue');

const TopUpRequest = () => import('@/entities/top-up-request/top-up-request.vue');
const TopUpRequestUpdate = () => import('@/entities/top-up-request/top-up-request-update.vue');
const TopUpRequestDetails = () => import('@/entities/top-up-request/top-up-request-details.vue');

const LikeCardProduct = () => import('@/entities/like-card-product/like-card-product.vue');
const LikeCardProductUpdate = () => import('@/entities/like-card-product/like-card-product-update.vue');
const LikeCardProductDetails = () => import('@/entities/like-card-product/like-card-product-details.vue');

const LikeCardCategory = () => import('@/entities/like-card-category/like-card-category.vue');
const LikeCardCategoryUpdate = () => import('@/entities/like-card-category/like-card-category-update.vue');
const LikeCardCategoryDetails = () => import('@/entities/like-card-category/like-card-category-details.vue');

const CommissionFee = () => import('@/entities/commission-fee/commission-fee.vue');
const CommissionFeeUpdate = () => import('@/entities/commission-fee/commission-fee-update.vue');
const CommissionFeeDetails = () => import('@/entities/commission-fee/commission-fee-details.vue');

const CommissionFeeTier = () => import('@/entities/commission-fee-tier/commission-fee-tier.vue');
const CommissionFeeTierUpdate = () => import('@/entities/commission-fee-tier/commission-fee-tier-update.vue');
const CommissionFeeTierDetails = () => import('@/entities/commission-fee-tier/commission-fee-tier-details.vue');

const GnsProduct = () => import('@/entities/gns-product/gns-product.vue');
const GnsProductUpdate = () => import('@/entities/gns-product/gns-product-update.vue');
const GnsProductDetails = () => import('@/entities/gns-product/gns-product-details.vue');

const AgentCommission = () => import('@/entities/agent-commission/agent-commission.vue');
const AgentCommissionUpdate = () => import('@/entities/agent-commission/agent-commission-update.vue');
const AgentCommissionDetails = () => import('@/entities/agent-commission/agent-commission-details.vue');

const TransactionLog = () => import('@/entities/transaction-log/transaction-log.vue');
const TransactionLogUpdate = () => import('@/entities/transaction-log/transaction-log-update.vue');
const TransactionLogDetails = () => import('@/entities/transaction-log/transaction-log-details.vue');

const AgentReferral = () => import('@/entities/agent-referral/agent-referral.vue');
const AgentReferralUpdate = () => import('@/entities/agent-referral/agent-referral-update.vue');
const AgentReferralDetails = () => import('@/entities/agent-referral/agent-referral-details.vue');

const FeatureFlag = () => import('@/entities/feature-flag/feature-flag.vue');
const FeatureFlagUpdate = () => import('@/entities/feature-flag/feature-flag-update.vue');
const FeatureFlagDetails = () => import('@/entities/feature-flag/feature-flag-details.vue');

const IncomingCall = () => import('@/entities/incoming-call/incoming-call.vue');
const IncomingCallUpdate = () => import('@/entities/incoming-call/incoming-call-update.vue');
const IncomingCallDetails = () => import('@/entities/incoming-call/incoming-call-details.vue');

const OneTimePassword = () => import('@/entities/one-time-password/one-time-password.vue');
const OneTimePasswordUpdate = () => import('@/entities/one-time-password/one-time-password-update.vue');
const OneTimePasswordDetails = () => import('@/entities/one-time-password/one-time-password-details.vue');

const PartnerCall = () => import('@/entities/partner-call/partner-call.vue');
const PartnerCallUpdate = () => import('@/entities/partner-call/partner-call-update.vue');
const PartnerCallDetails = () => import('@/entities/partner-call/partner-call-details.vue');

const Caching = () => import('@/entities/caching/caching.vue');
const CachingUpdate = () => import('@/entities/caching/caching-update.vue');
const CachingDetails = () => import('@/entities/caching/caching-details.vue');

const Device = () => import('@/entities/device/device.vue');
const DeviceUpdate = () => import('@/entities/device/device-update.vue');
const DeviceDetails = () => import('@/entities/device/device-details.vue');

const FailedAttempt = () => import('@/entities/failed-attempt/failed-attempt.vue');
const FailedAttemptUpdate = () => import('@/entities/failed-attempt/failed-attempt-update.vue');
const FailedAttemptDetails = () => import('@/entities/failed-attempt/failed-attempt-details.vue');

const FailedAttemptHistory = () => import('@/entities/failed-attempt-history/failed-attempt-history.vue');
const FailedAttemptHistoryUpdate = () => import('@/entities/failed-attempt-history/failed-attempt-history-update.vue');
const FailedAttemptHistoryDetails = () => import('@/entities/failed-attempt-history/failed-attempt-history-details.vue');

const ServiceClient = () => import('@/entities/service-client/service-client.vue');
const ServiceClientUpdate = () => import('@/entities/service-client/service-client-update.vue');
const ServiceClientDetails = () => import('@/entities/service-client/service-client-details.vue');

const UserAccess = () => import('@/entities/user-access/user-access.vue');
const UserAccessUpdate = () => import('@/entities/user-access/user-access-update.vue');
const UserAccessDetails = () => import('@/entities/user-access/user-access-details.vue');

// jhipster-needle-add-entity-to-router-import - JHipster will import entities to the router here

export default {
  path: '/',
  component: Entities,
  children: [
    {
      path: 'onboarding',
      name: 'Onboarding',
      component: Onboarding,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'onboarding/new',
      name: 'OnboardingCreate',
      component: OnboardingUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'onboarding/:onboardingId/edit',
      name: 'OnboardingEdit',
      component: OnboardingUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'onboarding/:onboardingId/view',
      name: 'OnboardingView',
      component: OnboardingDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'partner',
      name: 'Partner',
      component: Partner,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'partner/new',
      name: 'PartnerCreate',
      component: PartnerUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'partner/:partnerId/edit',
      name: 'PartnerEdit',
      component: PartnerUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'partner/:partnerId/view',
      name: 'PartnerView',
      component: PartnerDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'operation',
      name: 'Operation',
      component: Operation,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'operation/new',
      name: 'OperationCreate',
      component: OperationUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'operation/:operationId/edit',
      name: 'OperationEdit',
      component: OperationUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'operation/:operationId/view',
      name: 'OperationView',
      component: OperationDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'incentive',
      name: 'Incentive',
      component: Incentive,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'incentive/new',
      name: 'IncentiveCreate',
      component: IncentiveUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'incentive/:incentiveId/edit',
      name: 'IncentiveEdit',
      component: IncentiveUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'incentive/:incentiveId/view',
      name: 'IncentiveView',
      component: IncentiveDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'reward',
      name: 'Reward',
      component: Reward,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'reward/new',
      name: 'RewardCreate',
      component: RewardUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'reward/:rewardId/edit',
      name: 'RewardEdit',
      component: RewardUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'reward/:rewardId/view',
      name: 'RewardView',
      component: RewardDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'referral',
      name: 'Referral',
      component: Referral,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'referral/new',
      name: 'ReferralCreate',
      component: ReferralUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'referral/:referralId/edit',
      name: 'ReferralEdit',
      component: ReferralUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'referral/:referralId/view',
      name: 'ReferralView',
      component: ReferralDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'user-login',
      name: 'UserLogin',
      component: UserLogin,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'user-login/new',
      name: 'UserLoginCreate',
      component: UserLoginUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'user-login/:userLoginId/edit',
      name: 'UserLoginEdit',
      component: UserLoginUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'user-login/:userLoginId/view',
      name: 'UserLoginView',
      component: UserLoginDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'user-device',
      name: 'UserDevice',
      component: UserDevice,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'user-device/new',
      name: 'UserDeviceCreate',
      component: UserDeviceUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'user-device/:userDeviceId/edit',
      name: 'UserDeviceEdit',
      component: UserDeviceUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'user-device/:userDeviceId/view',
      name: 'UserDeviceView',
      component: UserDeviceDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'label',
      name: 'Label',
      component: Label,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'label/new',
      name: 'LabelCreate',
      component: LabelUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'label/:labelId/edit',
      name: 'LabelEdit',
      component: LabelUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'label/:labelId/view',
      name: 'LabelView',
      component: LabelDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'linked-label',
      name: 'LinkedLabel',
      component: LinkedLabel,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'linked-label/new',
      name: 'LinkedLabelCreate',
      component: LinkedLabelUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'linked-label/:linkedLabelId/edit',
      name: 'LinkedLabelEdit',
      component: LinkedLabelUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'linked-label/:linkedLabelId/view',
      name: 'LinkedLabelView',
      component: LinkedLabelDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'phone-number',
      name: 'PhoneNumber',
      component: PhoneNumber,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'phone-number/new',
      name: 'PhoneNumberCreate',
      component: PhoneNumberUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'phone-number/:phoneNumberId/edit',
      name: 'PhoneNumberEdit',
      component: PhoneNumberUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'phone-number/:phoneNumberId/view',
      name: 'PhoneNumberView',
      component: PhoneNumberDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'money-request',
      name: 'MoneyRequest',
      component: MoneyRequest,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'money-request/new',
      name: 'MoneyRequestCreate',
      component: MoneyRequestUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'money-request/:moneyRequestId/edit',
      name: 'MoneyRequestEdit',
      component: MoneyRequestUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'money-request/:moneyRequestId/view',
      name: 'MoneyRequestView',
      component: MoneyRequestDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'purchase',
      name: 'Purchase',
      component: Purchase,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'purchase/new',
      name: 'PurchaseCreate',
      component: PurchaseUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'purchase/:purchaseId/edit',
      name: 'PurchaseEdit',
      component: PurchaseUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'purchase/:purchaseId/view',
      name: 'PurchaseView',
      component: PurchaseDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'organisation',
      name: 'Organisation',
      component: Organisation,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'organisation/new',
      name: 'OrganisationCreate',
      component: OrganisationUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'organisation/:organisationId/edit',
      name: 'OrganisationEdit',
      component: OrganisationUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'organisation/:organisationId/view',
      name: 'OrganisationView',
      component: OrganisationDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'future-transaction',
      name: 'FutureTransaction',
      component: FutureTransaction,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'future-transaction/new',
      name: 'FutureTransactionCreate',
      component: FutureTransactionUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'future-transaction/:futureTransactionId/edit',
      name: 'FutureTransactionEdit',
      component: FutureTransactionUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'future-transaction/:futureTransactionId/view',
      name: 'FutureTransactionView',
      component: FutureTransactionDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'exchange-rate',
      name: 'ExchangeRate',
      component: ExchangeRate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'exchange-rate/new',
      name: 'ExchangeRateCreate',
      component: ExchangeRateUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'exchange-rate/:exchangeRateId/edit',
      name: 'ExchangeRateEdit',
      component: ExchangeRateUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'exchange-rate/:exchangeRateId/view',
      name: 'ExchangeRateView',
      component: ExchangeRateDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bank',
      name: 'Bank',
      component: Bank,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bank/new',
      name: 'BankCreate',
      component: BankUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bank/:bankId/edit',
      name: 'BankEdit',
      component: BankUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bank/:bankId/view',
      name: 'BankView',
      component: BankDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'ezp-product',
      name: 'EzpProduct',
      component: EzpProduct,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'ezp-product/new',
      name: 'EzpProductCreate',
      component: EzpProductUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'ezp-product/:ezpProductId/edit',
      name: 'EzpProductEdit',
      component: EzpProductUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'ezp-product/:ezpProductId/view',
      name: 'EzpProductView',
      component: EzpProductDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'ezp-gift',
      name: 'EzpGift',
      component: EzpGift,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'ezp-gift/new',
      name: 'EzpGiftCreate',
      component: EzpGiftUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'ezp-gift/:ezpGiftId/edit',
      name: 'EzpGiftEdit',
      component: EzpGiftUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'ezp-gift/:ezpGiftId/view',
      name: 'EzpGiftView',
      component: EzpGiftDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'biller',
      name: 'Biller',
      component: Biller,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'biller/new',
      name: 'BillerCreate',
      component: BillerUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'biller/:billerId/edit',
      name: 'BillerEdit',
      component: BillerUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'biller/:billerId/view',
      name: 'BillerView',
      component: BillerDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bank-transaction',
      name: 'BankTransaction',
      component: BankTransaction,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bank-transaction/new',
      name: 'BankTransactionCreate',
      component: BankTransactionUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bank-transaction/:bankTransactionId/edit',
      name: 'BankTransactionEdit',
      component: BankTransactionUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bank-transaction/:bankTransactionId/view',
      name: 'BankTransactionView',
      component: BankTransactionDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'agent',
      name: 'Agent',
      component: Agent,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'agent/new',
      name: 'AgentCreate',
      component: AgentUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'agent/:agentId/edit',
      name: 'AgentEdit',
      component: AgentUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'agent/:agentId/view',
      name: 'AgentView',
      component: AgentDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'qr-card',
      name: 'QrCard',
      component: QrCard,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'qr-card/new',
      name: 'QrCardCreate',
      component: QrCardUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'qr-card/:qrCardId/edit',
      name: 'QrCardEdit',
      component: QrCardUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'qr-card/:qrCardId/view',
      name: 'QrCardView',
      component: QrCardDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'transaction-fee',
      name: 'TransactionFee',
      component: TransactionFee,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'transaction-fee/new',
      name: 'TransactionFeeCreate',
      component: TransactionFeeUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'transaction-fee/:transactionFeeId/edit',
      name: 'TransactionFeeEdit',
      component: TransactionFeeUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'transaction-fee/:transactionFeeId/view',
      name: 'TransactionFeeView',
      component: TransactionFeeDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'transaction-fee-tier',
      name: 'TransactionFeeTier',
      component: TransactionFeeTier,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'transaction-fee-tier/new',
      name: 'TransactionFeeTierCreate',
      component: TransactionFeeTierUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'transaction-fee-tier/:transactionFeeTierId/edit',
      name: 'TransactionFeeTierEdit',
      component: TransactionFeeTierUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'transaction-fee-tier/:transactionFeeTierId/view',
      name: 'TransactionFeeTierView',
      component: TransactionFeeTierDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'top-up-request',
      name: 'TopUpRequest',
      component: TopUpRequest,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'top-up-request/new',
      name: 'TopUpRequestCreate',
      component: TopUpRequestUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'top-up-request/:topUpRequestId/edit',
      name: 'TopUpRequestEdit',
      component: TopUpRequestUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'top-up-request/:topUpRequestId/view',
      name: 'TopUpRequestView',
      component: TopUpRequestDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'like-card-product',
      name: 'LikeCardProduct',
      component: LikeCardProduct,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'like-card-product/new',
      name: 'LikeCardProductCreate',
      component: LikeCardProductUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'like-card-product/:likeCardProductId/edit',
      name: 'LikeCardProductEdit',
      component: LikeCardProductUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'like-card-product/:likeCardProductId/view',
      name: 'LikeCardProductView',
      component: LikeCardProductDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'like-card-category',
      name: 'LikeCardCategory',
      component: LikeCardCategory,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'like-card-category/new',
      name: 'LikeCardCategoryCreate',
      component: LikeCardCategoryUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'like-card-category/:likeCardCategoryId/edit',
      name: 'LikeCardCategoryEdit',
      component: LikeCardCategoryUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'like-card-category/:likeCardCategoryId/view',
      name: 'LikeCardCategoryView',
      component: LikeCardCategoryDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'commission-fee',
      name: 'CommissionFee',
      component: CommissionFee,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'commission-fee/new',
      name: 'CommissionFeeCreate',
      component: CommissionFeeUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'commission-fee/:commissionFeeId/edit',
      name: 'CommissionFeeEdit',
      component: CommissionFeeUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'commission-fee/:commissionFeeId/view',
      name: 'CommissionFeeView',
      component: CommissionFeeDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'commission-fee-tier',
      name: 'CommissionFeeTier',
      component: CommissionFeeTier,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'commission-fee-tier/new',
      name: 'CommissionFeeTierCreate',
      component: CommissionFeeTierUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'commission-fee-tier/:commissionFeeTierId/edit',
      name: 'CommissionFeeTierEdit',
      component: CommissionFeeTierUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'commission-fee-tier/:commissionFeeTierId/view',
      name: 'CommissionFeeTierView',
      component: CommissionFeeTierDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'gns-product',
      name: 'GnsProduct',
      component: GnsProduct,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'gns-product/new',
      name: 'GnsProductCreate',
      component: GnsProductUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'gns-product/:gnsProductId/edit',
      name: 'GnsProductEdit',
      component: GnsProductUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'gns-product/:gnsProductId/view',
      name: 'GnsProductView',
      component: GnsProductDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'agent-commission',
      name: 'AgentCommission',
      component: AgentCommission,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'agent-commission/new',
      name: 'AgentCommissionCreate',
      component: AgentCommissionUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'agent-commission/:agentCommissionId/edit',
      name: 'AgentCommissionEdit',
      component: AgentCommissionUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'agent-commission/:agentCommissionId/view',
      name: 'AgentCommissionView',
      component: AgentCommissionDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'transaction-log',
      name: 'TransactionLog',
      component: TransactionLog,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'transaction-log/new',
      name: 'TransactionLogCreate',
      component: TransactionLogUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'transaction-log/:transactionLogId/edit',
      name: 'TransactionLogEdit',
      component: TransactionLogUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'transaction-log/:transactionLogId/view',
      name: 'TransactionLogView',
      component: TransactionLogDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'agent-referral',
      name: 'AgentReferral',
      component: AgentReferral,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'agent-referral/new',
      name: 'AgentReferralCreate',
      component: AgentReferralUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'agent-referral/:agentReferralId/edit',
      name: 'AgentReferralEdit',
      component: AgentReferralUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'agent-referral/:agentReferralId/view',
      name: 'AgentReferralView',
      component: AgentReferralDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'feature-flag',
      name: 'FeatureFlag',
      component: FeatureFlag,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'feature-flag/new',
      name: 'FeatureFlagCreate',
      component: FeatureFlagUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'feature-flag/:featureFlagId/edit',
      name: 'FeatureFlagEdit',
      component: FeatureFlagUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'feature-flag/:featureFlagId/view',
      name: 'FeatureFlagView',
      component: FeatureFlagDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'incoming-call',
      name: 'IncomingCall',
      component: IncomingCall,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'incoming-call/new',
      name: 'IncomingCallCreate',
      component: IncomingCallUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'incoming-call/:incomingCallId/edit',
      name: 'IncomingCallEdit',
      component: IncomingCallUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'incoming-call/:incomingCallId/view',
      name: 'IncomingCallView',
      component: IncomingCallDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'one-time-password',
      name: 'OneTimePassword',
      component: OneTimePassword,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'one-time-password/new',
      name: 'OneTimePasswordCreate',
      component: OneTimePasswordUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'one-time-password/:oneTimePasswordId/edit',
      name: 'OneTimePasswordEdit',
      component: OneTimePasswordUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'one-time-password/:oneTimePasswordId/view',
      name: 'OneTimePasswordView',
      component: OneTimePasswordDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'partner-call',
      name: 'PartnerCall',
      component: PartnerCall,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'partner-call/new',
      name: 'PartnerCallCreate',
      component: PartnerCallUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'partner-call/:partnerCallId/edit',
      name: 'PartnerCallEdit',
      component: PartnerCallUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'partner-call/:partnerCallId/view',
      name: 'PartnerCallView',
      component: PartnerCallDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'caching',
      name: 'Caching',
      component: Caching,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'caching/new',
      name: 'CachingCreate',
      component: CachingUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'caching/:cachingId/edit',
      name: 'CachingEdit',
      component: CachingUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'caching/:cachingId/view',
      name: 'CachingView',
      component: CachingDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'device',
      name: 'Device',
      component: Device,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'device/new',
      name: 'DeviceCreate',
      component: DeviceUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'device/:deviceId/edit',
      name: 'DeviceEdit',
      component: DeviceUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'device/:deviceId/view',
      name: 'DeviceView',
      component: DeviceDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'failed-attempt',
      name: 'FailedAttempt',
      component: FailedAttempt,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'failed-attempt/new',
      name: 'FailedAttemptCreate',
      component: FailedAttemptUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'failed-attempt/:failedAttemptId/edit',
      name: 'FailedAttemptEdit',
      component: FailedAttemptUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'failed-attempt/:failedAttemptId/view',
      name: 'FailedAttemptView',
      component: FailedAttemptDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'failed-attempt-history',
      name: 'FailedAttemptHistory',
      component: FailedAttemptHistory,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'failed-attempt-history/new',
      name: 'FailedAttemptHistoryCreate',
      component: FailedAttemptHistoryUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'failed-attempt-history/:failedAttemptHistoryId/edit',
      name: 'FailedAttemptHistoryEdit',
      component: FailedAttemptHistoryUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'failed-attempt-history/:failedAttemptHistoryId/view',
      name: 'FailedAttemptHistoryView',
      component: FailedAttemptHistoryDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'service-client',
      name: 'ServiceClient',
      component: ServiceClient,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'service-client/new',
      name: 'ServiceClientCreate',
      component: ServiceClientUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'service-client/:serviceClientId/edit',
      name: 'ServiceClientEdit',
      component: ServiceClientUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'service-client/:serviceClientId/view',
      name: 'ServiceClientView',
      component: ServiceClientDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'user-access',
      name: 'UserAccess',
      component: UserAccess,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'user-access/new',
      name: 'UserAccessCreate',
      component: UserAccessUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'user-access/:userAccessId/edit',
      name: 'UserAccessEdit',
      component: UserAccessUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'user-access/:userAccessId/view',
      name: 'UserAccessView',
      component: UserAccessDetails,
      meta: { authorities: [Authority.USER] },
    },
    // jhipster-needle-add-entity-to-router - JHipster will add entities to the router here
  ],
};
