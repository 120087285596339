var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "b-dropdown-item",
        { attrs: { to: "/onboarding" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(_vm.$t("global.menu.entities.onboarding")),
              },
            },
            [_vm._v("Onboarding")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/partner" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(_vm.$t("global.menu.entities.partner")),
              },
            },
            [_vm._v("Partner")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/operation" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(_vm.$t("global.menu.entities.operation")),
              },
            },
            [_vm._v("Operation")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/incentive" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(_vm.$t("global.menu.entities.incentive")),
              },
            },
            [_vm._v("Incentive")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/reward" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(_vm.$t("global.menu.entities.reward")),
              },
            },
            [_vm._v("Reward")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/referral" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(_vm.$t("global.menu.entities.referral")),
              },
            },
            [_vm._v("Referral")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/user-login" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(_vm.$t("global.menu.entities.userLogin")),
              },
            },
            [_vm._v("User Login")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/user-device" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(_vm.$t("global.menu.entities.userDevice")),
              },
            },
            [_vm._v("User Device")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/label" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(_vm.$t("global.menu.entities.label")),
              },
            },
            [_vm._v("Label")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/linked-label" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(_vm.$t("global.menu.entities.linkedLabel")),
              },
            },
            [_vm._v("Linked Label")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/phone-number" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(_vm.$t("global.menu.entities.phoneNumber")),
              },
            },
            [_vm._v("Phone Number")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/money-request" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(
                  _vm.$t("global.menu.entities.moneyRequest")
                ),
              },
            },
            [_vm._v("Money Request")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/purchase" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(_vm.$t("global.menu.entities.purchase")),
              },
            },
            [_vm._v("Purchase")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/organisation" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(
                  _vm.$t("global.menu.entities.organisation")
                ),
              },
            },
            [_vm._v("Organisation")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/future-transaction" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(
                  _vm.$t("global.menu.entities.futureTransaction")
                ),
              },
            },
            [_vm._v("Future Transaction")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/exchange-rate" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(
                  _vm.$t("global.menu.entities.exchangeRate")
                ),
              },
            },
            [_vm._v("Exchange Rate")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/bank" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(_vm.$t("global.menu.entities.bank")),
              },
            },
            [_vm._v("Bank")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/ezp-product" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(_vm.$t("global.menu.entities.ezpProduct")),
              },
            },
            [_vm._v("Ezp Product")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/ezp-gift" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(_vm.$t("global.menu.entities.ezpGift")),
              },
            },
            [_vm._v("Ezp Gift")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/biller" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(_vm.$t("global.menu.entities.biller")),
              },
            },
            [_vm._v("Biller")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/bank-transaction" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(
                  _vm.$t("global.menu.entities.bankTransaction")
                ),
              },
            },
            [_vm._v("Bank Transaction")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/agent" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(_vm.$t("global.menu.entities.agent")),
              },
            },
            [_vm._v("Agent")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/qr-card" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(_vm.$t("global.menu.entities.qrCard")),
              },
            },
            [_vm._v("Qr Card")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/transaction-fee" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(
                  _vm.$t("global.menu.entities.transactionFee")
                ),
              },
            },
            [_vm._v("Transaction Fee")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/transaction-fee-tier" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(
                  _vm.$t("global.menu.entities.transactionFeeTier")
                ),
              },
            },
            [_vm._v("Transaction Fee Tier")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/top-up-request" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(
                  _vm.$t("global.menu.entities.topUpRequest")
                ),
              },
            },
            [_vm._v("Top Up Request")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/like-card-product" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(
                  _vm.$t("global.menu.entities.likeCardProduct")
                ),
              },
            },
            [_vm._v("Like Card Product")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/like-card-category" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(
                  _vm.$t("global.menu.entities.likeCardCategory")
                ),
              },
            },
            [_vm._v("Like Card Category")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/commission-fee" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(
                  _vm.$t("global.menu.entities.commissionFee")
                ),
              },
            },
            [_vm._v("Commission Fee")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/commission-fee-tier" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(
                  _vm.$t("global.menu.entities.commissionFeeTier")
                ),
              },
            },
            [_vm._v("Commission Fee Tier")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/gns-product" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(_vm.$t("global.menu.entities.gnsProduct")),
              },
            },
            [_vm._v("Gns Product")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/agent-commission" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(
                  _vm.$t("global.menu.entities.agentCommission")
                ),
              },
            },
            [_vm._v("Agent Commission")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/transaction-log" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(
                  _vm.$t("global.menu.entities.transactionLog")
                ),
              },
            },
            [_vm._v("Transaction Log")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/agent-referral" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(
                  _vm.$t("global.menu.entities.agentReferral")
                ),
              },
            },
            [_vm._v("Agent Referral")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/feature-flag" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(_vm.$t("global.menu.entities.featureFlag")),
              },
            },
            [_vm._v("Feature Flag")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/incoming-call" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(
                  _vm.$t("global.menu.entities.incomingCall")
                ),
              },
            },
            [_vm._v("Incoming Call")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/one-time-password" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(
                  _vm.$t("global.menu.entities.oneTimePassword")
                ),
              },
            },
            [_vm._v("One Time Password")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/partner-call" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(_vm.$t("global.menu.entities.partnerCall")),
              },
            },
            [_vm._v("Partner Call")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/caching" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(_vm.$t("global.menu.entities.caching")),
              },
            },
            [_vm._v("Caching")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/device" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(_vm.$t("global.menu.entities.device")),
              },
            },
            [_vm._v("Device")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/failed-attempt" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(
                  _vm.$t("global.menu.entities.failedAttempt")
                ),
              },
            },
            [_vm._v("Failed Attempt")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/failed-attempt-history" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(
                  _vm.$t("global.menu.entities.failedAttemptHistory")
                ),
              },
            },
            [_vm._v("Failed Attempt History")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/service-client" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(
                  _vm.$t("global.menu.entities.serviceClient")
                ),
              },
            },
            [_vm._v("Service Client")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/user-access" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(_vm.$t("global.menu.entities.userAccess")),
              },
            },
            [_vm._v("User Access")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }